import { toast } from "react-toastify";
import { unAuthorizedPost, post } from "services";
import {
    FETCH_NFT_DETAILS,
    FETCH_ALL_SELLING_NFTS,
    FETCH_ALL_USER_NFTS,
    SELL_AN_NFT,
    SAVE_NFT_DETAILS,
    BUY_METAPLEX_NFT,
    MOVE_NFT_TO_ANOTHER_WALLET,
    BUY_NFT_USING_EMAIL,
} from "graphql";
import {
    TOGGLE_LOADING,
    LIST_NFT_DETAILS,
    LIST_ALL_SELLING_NFTS,
    LIST_ALL_USER_NFTS,
    RESET_SELLING_NFTS,
    RESET_USER_NFTS,
    CLOSE_MODAL,
} from "../types";
import { history } from "utils";

export const listNftDetails = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: FETCH_NFT_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data?.customStatus) {
            let {
                list_nft_details: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_NFT_DETAILS,
                payload: data,
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllSellingNfts = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        dispatch({ type: CLOSE_MODAL });

        const { pageSize, pageNumber, list } = getState().nfts;

        let apiResponse = await unAuthorizedPost("", {
            query: FETCH_ALL_SELLING_NFTS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
            },
        });

        if (apiResponse.data?.customStatus) {
            let {
                list_all_selling_nfts: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_SELLING_NFTS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllUserNfts = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        dispatch({ type: CLOSE_MODAL });

        const {
            userNfts: { pageSize, pageNumber, list },
        } = getState().nfts;

        let apiResponse = await post("", {
            query: FETCH_ALL_USER_NFTS,
            variables: {
                pageNumber: pageNumber,
                pageSize: pageSize,
                ...requestData,
            },
        });

        if (apiResponse.data?.customStatus) {
            let {
                list_all_user_nfts: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_USER_NFTS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const sellAnNft = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: SELL_AN_NFT,
            variables: requestData,
        });

        if (apiResponse.data?.customStatus) {
            let {
                sell_nfts: { message },
            } = apiResponse.data.data;
            toast.success("Artwork added for sales");
            dispatch({ type: CLOSE_MODAL });
            history.push("/auth/listings");
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const purchaseNft = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: BUY_METAPLEX_NFT,
            variables: requestData,
        });

        if (apiResponse.data?.customStatus) {
            // let {
            //     buy_nft: { message },
            // } = apiResponse.data.data;

            toast.success("Transaction Success");
            dispatch({ type: CLOSE_MODAL });
            history.push("/auth/listings");
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const saveNftDetails = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: SAVE_NFT_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data?.customStatus) {
            // let {
            //     buy_nft: { message },
            // } = apiResponse.data.data;

            toast.success("Artwork created");
            history.push("/auth/listings");
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetSellingNfts = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: false });
        dispatch({ type: RESET_SELLING_NFTS });
    };
};

export const resetUserNfts = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_USER_NFTS });
    };
};

export const moveNftToAnotherWallet = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: MOVE_NFT_TO_ANOTHER_WALLET,
            variables: requestData,
        });

        if (apiResponse.data?.customStatus) {
            toast.success("Successfully moved to your wallet");
            dispatch({ type: CLOSE_MODAL });
            history.push("/auth/listings");
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const purchaseNftUsingEmail = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: BUY_NFT_USING_EMAIL,
            variables: requestData,
        });

        if (apiResponse.data?.customStatus) {
            // let {
            //     buy_nft: { message },
            // } = apiResponse.data.data;

            toast.success("Transaction Success");
            dispatch({ type: CLOSE_MODAL });
            history.push("/auth/listings");
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};
