/*eslint-disable*/
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";

import { apiCall, toggleLoading } from "store/actions";
import { CREATE_NEW_ORDER } from "graphql";
import { cryptoConverter, getBlockchainNetwork, getCurrencyName, getBlockchainCurrency } from "utils";
import { Box, Button, Card, InputAdornment, OutlinedInput, Stack, Typography } from "@mui/material";
import { Email, Phone } from "@material-ui/icons";
import { convertToSOL } from "helpers";

export default ({ dispatch, handlePage, handlePaymentDetails, paymentDetails, setOpen, nftId, nftDetails }) => {
    const [amount, setAmount] = useState(convertToSOL(nftDetails?.selling_price));
    const [convertTo, setConvertTo] = useState("solana");

    const handleContinue = async (data) => {
        dispatch(toggleLoading(true));
        data.merchantId = "6346c261e290c8c2adfa9fe6";

        let network = "solana";
        if (data.currency.length) {
            network = data.currency[0].value;
        }
        data.network = network;
        data.amount = parseFloat(amount);

        const response = await apiCall(paymentDetails.baseUrl, CREATE_NEW_ORDER, data);
        if (!response.status) {
            toast.error("Failed to initiate order");
            dispatch(toggleLoading(false));
            return;
        }
        const { order_id, wallet_address } = response.data?.create_new_order;

        handlePaymentDetails({
            ...paymentDetails,
            orderId: order_id,
            walletAddress: wallet_address,
            amount: data.amount,
            network: network,
            email: data.email,
        });
        dispatch(toggleLoading(false));
        return handlePage(3);
    };

    useEffect(async () => {
        setAmount(await cryptoConverter(paymentDetails?.network, convertTo, convertToSOL(nftDetails?.selling_price)));
    }, [convertTo]);

    const generateOptions = (value = "") => {
        let options = [
            { value: "solana", label: "Solana" },
            { value: "ethereum", label: "Ethereum (ethereum chain)" },
            { value: "avalanche", label: "Avalanche (ethereum chain)" },
            { value: "polygon", label: "Polygon Matic (ethereum chain)" },
        ];
        if (!value) {
            return options;
        } else {
            return options.filter((e) => e.value === value);
        }
    };

    return (
        <>
            <Card sx={{ borderRadius: "10px" }}>
                <Box sx={{ p: 3, width: "100%" }}>
                    <Stack spacing={2} sx={{ mb: 3 }}>
                        <div style={{ padding: 10, borderRadius: "10px", backgroundColor: "#f0f0f0" }} className="amount">
                            <div style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }} className="box">
                                <img src={nftDetails?.art_url} />
                            </div>
                            <div className="content">
                                <h3>Amount payable:</h3>
                                <b style={{ textTransform: "uppercase" }}>
                                    {amount} {getBlockchainCurrency(convertTo)}
                                </b>
                                <br />
                            </div>
                        </div>
                    </Stack>
                    <div>
                        <Formik
                            initialValues={{
                                email: "",
                                currency: generateOptions(getCurrencyName(paymentDetails.currency)),
                                // phone: "",
                                // amount: isAmountFixed ? 0 : 0.02,
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
                            })}
                            onSubmit={(value) => {
                                return new Promise((resolve, reject) => {
                                    handleContinue(value).then(() => {
                                        resolve({});
                                    });
                                });
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form onSubmit={handleSubmit}>
                                    <Stack spacing={2}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <label style={{ width: "40%" }}>
                                                <b>Currency</b>
                                            </label>
                                            <Stack sx={{ width: "100%" }}>
                                                <Select
                                                    key="currency"
                                                    name="currency"
                                                    defaultValue={generateOptions(getCurrencyName(paymentDetails.currency))}
                                                    options={generateOptions()}
                                                    isOptionDisabled={(option) => option.disabled}
                                                    isClearable={false}
                                                    value={values.currency}
                                                    onChange={(selectedOption) => {
                                                        let event = {
                                                            target: { name: "currency", value: [selectedOption] },
                                                        };
                                                        handleChange(event);
                                                        setConvertTo(selectedOption.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                            </Stack>
                                        </Stack>

                                        {/* {!isAmountFixed ? (
                                            <div className="relative w-full mb-3">
                                                <label> Amount </label>
                                                <OutlinedInput
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    // name="amount"
                                                    placeholder="amount"
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    onWheel={(e) => e.target.blur()}
                                                    inputProps={{ min: 0.02 }}
                                                />
                                            </div>
                                        ) : null} */}

                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <label style={{ width: "40%" }}>
                                                <b>Email</b>
                                            </label>
                                            <Stack sx={{ width: "100%" }}>
                                                <OutlinedInput
                                                    fullWidth
                                                    size="small"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <Email fontSize="small" />
                                                        </InputAdornment>
                                                    }
                                                />
                                                {Boolean(touched.email && errors.email) ? (
                                                    <div
                                                        style={{
                                                            display: "block",
                                                            marginLeft: "10px",
                                                            color: "red",
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        {errors.email}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </Stack>
                                        </Stack>
                                        {/* <Stack direction='row' spacing={1} alignItems='center'>
                                            <label style={{ width: '50%' }}>
                                                Phone
                                            </label>
                                            <Stack sx={{ width: "100%" }}>
                                                <OutlinedInput
                                                    fullWidth
                                                    size="small"
                                                    placeholder="Enter phone"
                                                    type="number"
                                                    name="phone"
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <Phone fontSize="small" />
                                                        </InputAdornment>
                                                    }
                                                />
                                                {Boolean(touched.phone && errors.phone) ? (
                                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                                        {errors.phone}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </Stack>
                                        </Stack> */}

                                        <Button
                                            fullWidth
                                            variant="contained"
                                            type="submit"
                                            onClick={handleSubmit}
                                            disabled={values.email === "" || amount === 0}
                                        >
                                            <b>
                                                Pay {amount} {getBlockchainCurrency(convertTo)}
                                            </b>
                                        </Button>
                                        <Button fullWidth variant="text" onClick={() => setOpen(false)}>
                                            <small>
                                                <b>Cancel</b>
                                            </small>
                                        </Button>
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    </div>
                </Box>
            </Card>
        </>
    );
};
