/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import { toast } from "react-toastify";

import { apiCall } from "store/actions";
import { CHECK_FOR_ORDER_TXN } from "graphql";
// import { getBlockchainCurrency } from "utils";

import Loader from "components/Loader";
import "./countdown.css";
import { Box, Card, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import { getBlockchainCurrency } from "utils";
import { useDispatch } from "react-redux";
import { purchaseNftUsingEmail } from "store/actions";

export default ({ paymentDetails, nftId, setOpen }) => {
    const dispatch = useDispatch();
    const [minutes, setMinutes] = useState(9);
    const [seconds, setSeconds] = useState(59);
    const [apiCallCount, setApiCallCount] = useState(0);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (!minutes && !seconds) return;
        const intervalId = setInterval(() => {
            if (seconds) {
                setSeconds(seconds - 1);
            } else {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
    }, [seconds]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await apiCall(paymentDetails.baseUrl, CHECK_FOR_ORDER_TXN, {
                orderId: paymentDetails.orderId,
            });
            setApiCallCount(apiCallCount + 1);

            if (!response.status) {
                return;
            }

            const {
                check_for_order_txns: { status, message },
            } = response.data;

            if (status) {
                // toast.success(message ?? "Transaction was successful");
                dispatch(
                    purchaseNftUsingEmail({ email: paymentDetails.email, nftId: nftId, signature: paymentDetails.orderId })
                );
                return setOpen(false);
            }
        };

        const timer = setTimeout(() => {
            fetchData();
        }, 15000);

        return () => clearTimeout(timer);
    }, [apiCallCount]);

    return (
        <>
            <Card sx={{ borderRadius: "10px" }}>
                <Box sx={{ p: 3, width: "100%" }}>
                    <Typography style={{ width: "100%", textAlign: "center" }} variant="h5">
                        {paymentDetails.orderId}
                    </Typography>

                    <Stack sx={{ mt: 3, width: "100%" }} spacing={2}>
                        <Typography style={{ width: "100%", textAlign: "center" }} variant="subtitle2">
                            You need to carry out a transaction of{" "}
                            <strong style={{ color: "green", textTransform: "uppercase" }}>
                                {paymentDetails.amount} {getBlockchainCurrency(paymentDetails?.network ?? "")}
                            </strong>{" "}
                            to the wallet address
                        </Typography>

                        <div className="id-view">
                            <div className="id">{paymentDetails.walletAddress}</div>
                            <CopyToClipboard text={paymentDetails.walletAddress} onCopy={() => setCopied(true)}>
                                <button style={{ backgroundColor: !copied ? "rgb(0, 81, 255)" : "#6BC654" }}>
                                    {!copied ? "Copy" : "copied"}
                                </button>
                            </CopyToClipboard>
                        </div>

                        {!minutes && !seconds ? (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <strong>Transaction Timeout</strong>
                            </div>
                        ) : (
                            <>
                                <Loader type="watch" />
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <strong>
                                        0{minutes}:{seconds >= 10 ? seconds : `0${seconds}`}
                                    </strong>
                                </div>
                                <br />
                                <div>
                                    <ul style={{ listStyleType: "circle", fontSize: "small" }}>
                                        <li>You have 10 minutes to complete this transaction.</li>
                                        <li>You will receive an email once you complete the transaction.</li>
                                        <li>Do not close or refresh this window</li>
                                    </ul>
                                </div>
                            </>
                        )}
                    </Stack>
                </Box>
            </Card>
        </>
    );
};
