import React from "react";
import Footer from "views/shared/footer";
import Navbar from "views/shared/navbar";
import './style.css';

const LandingLayout = () => {
  return (
    <div>
      <Navbar />
      {/* ***************************** Banner Starts Here **********************************/}
      <section
        className="container-fluid banner-container"
        style={{ backgroundColor: "rgb(26,27,40)", color: "white" }}
      >
        <div className="container">
          <div className="row banner-row">
            <div className="col-md-6 banner-txt">
              <h1>NFT Platform as a Service</h1>
              <p>
              NFT infrastructure for businesses to handle rewards, incentives, beneﬁts & payouts
              </p>
              {/* <p>
                invest in privately held companies from the comfort of your
                mobile phone in just a couple of clicks
              </p> */}
              <div className="btn-row row">
              <a href="/auth/listings" className="btn">
                <button className="btn btn-outline-primary clr"
                style={{borderColor:"#F9CA26",color:"#F9CA26"}}>
                  View listed NFTs
                </button>
                </a>

                <a href="/auth/contactus" className="btn">
                  <button
                    className="btn btn-primary clr"
                    style={{
                      backgroundColor: "#F9CA26",
                      color: "black",
                      border: 0,
                    }}
                  >
                    Get early access
                  </button>
                </a>
              </div>
            </div>
            <div className="col-md-6 banner-img">
              <img src="assets/images/buy-sell.svg" alt="" />
            </div>
            <br />
            &nbsp;
          </div>
        </div>
      </section>
      {/* ***************************** Key Features Starts Here **********************************/}
      {/* <div className="container-fluid key-featurecont">
        <div className="container">
          <div className="row key-ro">
            <div className="col-md-4 keycol">
              <div className="key-cover">
                <div className="logo-cc">
                  <img src="assets/images/rupee_bag.png" alt="" />
                </div>
                <div className="text-cc">
                  <h3>start small</h3>
                  <p>
                    one can start the private market investing with a very small
                    amount
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 keycol">
              <div className="key-cover">
                <div className="logo-cc">
                  <img src="assets/images/reseller_icon.png" alt="" />
                </div>
                <div className="text-cc">
                  <h3>resell</h3>
                  <p>
                    options available to resell incase the user needs liquidity
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 keycol">
              <div className="key-cover">
                <div className="logo-cc">
                  <img src="assets/images/returns.png" alt="" />
                </div>
                <div className="text-cc">
                  <h3>returns</h3>
                  <p>
                    ability to invest early with potentially exponential return
                    on investments
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* ***************************** About us Starts Here **********************************/}
      {/* <section
        className="container-fluid about-coo"
        style={{ backgroundColor: "rgb(38,39,52)", color: "white" }}
      >
        <div className="container" style={{textAlign:"center"}}>
          
        </div>
      </section> */}
      {/**************** Features Starts Here ****************/}
      <section
        id="features"
        className="features container-fluid"
        style={{ backgroundColor: "rgb(38,39,52)" }}
      >
        <div className="container">
          <div className="row section-title" style={{ color: "white" }}>
            <h2>Key Benefits</h2>
          </div>
          <div className="row mt-5 feature-row">
            <div className="col-md-3">
              <div className="feature-col">
                <img
                  src="https://i.ibb.co/r7ryJDz/image.png"
                  alt=""
                  style={{ width: "130px", height: "130px" }}
                  />
                  <h4>Refund Guarantee</h4>
                <p>If you want to get a refund for the NFT you have purchased from
                our creators within a time frame (30 days as we launch). You get
                100% refund from our creators but we deduct a 5% market place
                fee.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="feature-col">
                <img
                  src="https://i.ibb.co/SxsF0V2/image.png"
                  alt=""
                  style={{ width: "130px", height: "130px" }}
                  />
                  <h4>NFT Stake Pool</h4>
                <p>
                We will be soon launching a stakepool for all our members who
                wish to hold their NFT for long term. Members who are part of
                the stakepool will be rewarded.
              </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="feature-col">
                <img
                  src="https://i.ibb.co/V9VcwvF/image.png"
                  alt=""
                  style={{ width: "130px", height: "130px" }}
                  />
                  <h4>Easy to share</h4>
                <p>
                You can now share/reward your NFT to any of your friend who
                doesnt even have a web3 wallet by just entering their email ID.
              </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="feature-col">
                <img
                  src="https://i.ibb.co/yfvPx9f/image.png"
                  alt=""
                  style={{ width: "130px", height: "130px" }}
                  />
                  <h4>Royalty not just for creators</h4>
                <p>We provide members an option to earn royalty from a resale.</p>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      {/*  ************************* Blog Starts Here ************************** */}
      {/* <div
        className="blog"
        id="blog"
        style={{ backgroundColor: "rgb(38,39,52)" }}
      >
        <div className="container">
          <div className="section-title row" style={{ color: "white" }}>
            <h2>innovative startups</h2>
          </div>
          <div className="row mt-5 blog-row">
            <div className="col-md-6 col-sm-12" >
              <div className="blog-singe no-margin row">
                <div className="col-sm-5 blog-img-tab">
                  <img
                    src="https://i.ibb.co/FxkkSLj/image.png"
                    alt=""
                    style={{ height: "150px" }}
                  />
                </div>
                <div className="col-sm-7 blog-content-tab">
                  <h2>Absolute Mind Private Limited</h2>
                  <small>
                    <p style={{ color: "gray" }}>health tech</p>
                    <p>Fitness & Wellness</p>
                  </small>
                  <a href="blog_single.html">
                    Explore <i className="fas fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="blog-singe no-margin row">
                <div className="col-sm-5 blog-img-tab">
                  <img
                    src="https://i.ibb.co/8dDTvKS/image.png"
                    alt=""
                    style={{ height: "150px" }}
                  />
                </div>
                <div className="col-sm-7 blog-content-tab">
                  <h2>Abix Global Private Limited</h2>
                  <small>
                    <p style={{ color: "gray" }}>HealthTech,Technology</p>
                    <p>Consumer HealthTech</p>
                  </small>
                  <a href="blog_single.html">
                    Explore <i className="fas fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="blog-singe no-margin row">
                <div className="col-sm-5 blog-img-tab">
                  <img
                    src="https://i.ibb.co/KryKqpK/image.png"
                    alt=""
                    style={{ height: "150px" }}
                  />
                </div>
                <div className="col-sm-7 blog-content-tab">
                  <h2>1074 Vectors (P) Ltd</h2>
                  <small>
                    <p style={{ color: "gray" }}>Technology</p>
                    <p>Geographic Information Systems</p>
                  </small>
                  <a href="blog_single.html">
                    Explore <i className="fas fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="blog-singe no-margin row">
                <div className="col-sm-5 blog-img-tab">
                  <img
                    src="https://i.ibb.co/7ynwxBG/image.png"
                    alt=""
                    style={{ height: "150px" }}
                  />
                </div>
                <div className="col-sm-7 blog-content-tab">
                  <h2>Aamshe Technology LLP</h2>
                  <small>
                    <p style={{ color: "gray" }}>Financial Services</p>
                    <p>
                      Finance & Accounting Tech,Microfinance,Consumer Finance
                    </p>
                  </small>
                  <a href="blog_single.html">
                    Explore <i className="fas fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <a href="blog_single.html" style={{ textAlign: "center" }}>
              View more <i className="fas fa-arrow-right" />
            </a>
          </div>
        </div>
      </div> */}

      {/* <section className="testimonial-container" style={{ backgroundColor: "rgb(38,39,52)",color:"white" }}>
        <div className="container">
          <div className="section-title row">
            <h2>innovative startups</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-offset-2 float-auto col-md-10">
              <div id="testimonial-slider" className="owl-carousel">
                <div className="testimonial">
                  <div className="pic">
                  <img
                    src="https://i.ibb.co/7ynwxBG/image.png"
                    alt=""
                  />
                  </div>
                  <div className="description">
                  <h4>Aamshe Technology LLP</h4>
                  <small>
                    <p style={{ color: "gray" }}>financial services</p>
                    <p>
                      finance & accounting tech, microfinance, consumer finance
                    </p>
                  </small>
                  <a href="blog_single.html">
                    
                  </a>
                </div>
                <a onClick={() => history.push(`/startups`)}>
                  <h3 className="title">
                  Explore <i className="fas fa-arrow-right" />
                  </h3>
                  </a>
                </div>
                <div className="testimonial">
                  <div className="pic">
                  <img
                    src="https://i.ibb.co/KryKqpK/image.png"
                    alt=""
                  />
                  </div>
                  <div className="description">
                  <h4>1074 Vectors</h4>
                  <small>
                    <p style={{ color: "gray" }}>technology</p>
                    <p>geographic information systems</p>
                  </small>
                  <a href="blog_single.html">
                    
                  </a>
                </div>
                <a onClick={() => history.push(`/startups`)}>
                  <h3 className="title">
                  Explore <i className="fas fa-arrow-right" />
                  </h3>
                  </a>
                </div>
                <div className="testimonial">
                  <div className="pic">
                  <img
                    src="https://i.ibb.co/FxkkSLj/image.png"
                    alt=""
                  />
                  </div>
                  <div className="description">
                  <h4>Absolute Mind</h4>
                  <small>
                    <p style={{ color: "gray" }}>health tech</p>
                    <p>fitness & wellness</p>
                  </small>
                  <a href="blog_single.html">
                    
                  </a>
                </div>
                <a onClick={() => history.push(`/startups`)}>
                  <h3 className="title">
                  Explore <i className="fas fa-arrow-right" />
                  </h3>
                  </a>
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
            <a href="/startups" style={{color:"#F9CA26"}}>
              <br/>
              <br/>
              View more <i className="fas fa-arrow-right" />
            </a>
            </div>
          </div>
        </div>
      </section> */}
      {/* ################# Testimonial Starts Here#######################-*/}
      {/* <section className="testimonial-container">
        <div className="container">
          <div className="section-title row">
            <h2>Testimonial</h2>
            <p>Take a look at what people say about us</p>
          </div>
          <div className="row mt-5">
            <div className="col-md-offset-2 float-auto col-md-10">
              <div id="testimonial-slider" className="owl-carousel">
                <div className="testimonial">
                  <div className="pic">
                    <img src="assets/images/testimonial/member-01.jpg" alt="" />
                  </div>
                  <p className="description">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Assumenda deleniti dolor ipsum molestias mollitia ut.
                    Aliquam aperiam corporis cumque debitis delectus
                    dignissimos. Lorem ipsum dolor sit amet, consectetur.
                  </p>
                  <h3 className="title">
                    williamson
                    <span className="post"> - Developer</span>
                  </h3>
                </div>
                <div className="testimonial">
                  <div className="pic">
                    <img src="assets/images/testimonial/member-02.jpg" alt="" />
                  </div>
                  <p className="description">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Assumenda deleniti dolor ipsum molestias mollitia ut.
                    Aliquam aperiam corporis cumque debitis delectus
                    dignissimos. Lorem ipsum dolor sit amet, consectetur.
                  </p>
                  <h3 className="title">
                    Kristina
                    <span className="post"> - Teacher</span>
                  </h3>
                </div>
                <div className="testimonial">
                  <div className="pic">
                    <img src="assets/images/testimonial/member-02.jpg" alt="" />
                  </div>
                  <p className="description">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Assumenda deleniti dolor ipsum molestias mollitia ut.
                    Aliquam aperiam corporis cumque debitis delectus
                    dignissimos. Lorem ipsum dolor sit amet, consectetur.
                  </p>
                  <h3 className="title">
                    Kristina
                    <span className="post"> - Teacher</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css"
      />
    </div>
  );
};

export default LandingLayout;
