import React from 'react';
import * as Yup from "yup";
import { Formik } from 'formik';
import { Box, Card, Stack, OutlinedInput, Button } from '@mui/material';
import { PublicKey } from '@solana/web3.js';
import { toast } from 'react-toastify';

function DestWalletAddress({ handlePage, setOpen, handlePaymentDetails, paymentDetails }) {

    const validateSolAddress = (address) => {
        try {
            let pubkey = new PublicKey(address);
            let isSolana = PublicKey.isOnCurve(pubkey.toBuffer());
            if (isSolana) {
                handlePaymentDetails({ ...paymentDetails, destWalletId: address });
                return handlePage(2);
            }
        } catch (error) {
            return toast.error("Please provide a valid solana wallet address");
        }
    };

    return (
        <>
            <Card sx={{ borderRadius: "10px" }}>
                <Box sx={{ p: 3, width: "100%" }}>
                    <Formik
                        initialValues={{
                            destWalletId: "",
                        }}
                        validationSchema={Yup.object().shape({
                            destWalletId: Yup.string().max(255).required("Wallet address is required"),
                        })}
                        onSubmit={(value) => {
                            validateSolAddress(value.destWalletId);
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                            <form
                                onSubmit={() => {
                                    handleSubmit(values);
                                }}
                            >
                                <Stack spacing={2} >
                                    <label>
                                        <b>Destination Wallet Address</b>
                                    </label>
                                    <Stack sx={{ width: "100%" }}>
                                        <OutlinedInput
                                            key="destWalletId"
                                            name="destWalletId"
                                            autoComplete="off"
                                            type="text"
                                            onBlur={handleBlur}
                                            placeholder="Enter destination wallet id"
                                            onChange={handleChange}
                                            value={values.destWalletId}
                                            error={Boolean(touched.destWalletId && errors.destWalletId)}
                                        />
                                        {Boolean(touched.destWalletId && errors.destWalletId) ? (
                                            <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 13 }}>
                                                {errors.destWalletId}
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                    </Stack>
                                    <Button
                                        variant='contained'
                                        onClick={handleSubmit}
                                        disabled={values.destWalletId === ""}
                                        type="submit"
                                    >
                                        Continue
                                    </Button>
                                    <Button
                                        onClick={() => setOpen(false)}
                                    >
                                        Cancel
                                    </Button>

                                    {/* <div>
                                        <small>
                                            If you don't have a wallet address{" "}
                                            <a href="https://renai.io/auth/signup" target="_self">
                                                signup
                                            </a>{" "}
                                            to create one
                                        </small>
                                    </div> */}
                                </Stack>
                            </form>
                        )}
                    </Formik>

                </Box>
            </Card>
        </>
    )
}

export default DestWalletAddress