/*eslint-disable*/
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';

//components
import FirstPage from "./first-page";
import SecondPage from "./countdown";
import DestWalletAddress from "./dest-wallet-address";
// import CloseConfirmationPage from "./close-confirmation-page";

// import { fetchBaseURL } from "utils";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 380,
    outline: "none",
    boxShadow: 24,
    p: 4,
};

export default function ({ setOpen, nftId, nftDetails }) {
    const dispatch = useDispatch();
    const [page, setPage] = useState(2);
    const [details, setDetails] = useState({
        orderId: "",
        walletAddress: "",
        email:'',
        amount: 0.01,
        network: "SOL",
        baseUrl: "https://api-pay.thetokenlist.com/graphql/payments",
    });

    const { loading } = useSelector(({ loading }) => ({
        loading: loading,
    }));

    const handlePage = (data) => {
        setPage(data);
    };

    const handlePaymentDetails = (data) => {
        setDetails(data);
    };

    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <CircularProgress color="primary" />
            </Backdrop>
            <div
                style={style}
            >
                {page === 1 ? (
                    <DestWalletAddress 
                        handlePage={handlePage} 
                        setOpen={setOpen}
                        handlePaymentDetails={handlePaymentDetails}
                        paymentDetails={details}
                    />
                ) : page === 2 ? (
                    <FirstPage
                        dispatch={dispatch}
                        handlePage={handlePage}
                        handlePaymentDetails={handlePaymentDetails}
                        paymentDetails={details}
                        setOpen={setOpen}
                        nftId={nftId}
                        nftDetails={nftDetails}
                    />
                ) : page === 3 ? (
                    <SecondPage 
                        paymentDetails={details} 
                        nftId={nftId}
                        setOpen={setOpen}
                    />
                // ) : page === 4 ? (
                //     <CloseConfirmationPage />
                ) : null}
            </div>

        </>
    );
}
