import React from "react";
import { ProgressBar, Watch } from "react-loader-spinner";
import { Backdrop } from "@material-ui/core";

const Loader = ({ open = false, type = "progressBar" }) => {
    return (
        <>
            {type === "progressBar" ? (
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <ProgressBar
                            height="80"
                            width="80"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{}}
                            wrapperClass="progress-bar-wrapper"
                            borderColor="#F4442E"
                            barColor="#51E5FF"
                        />
                    </div>
                </Backdrop>
            ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Watch
                        height="80"
                        width="80"
                        radius="48"
                        color="#4fa94d"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            )}
        </>
    );
};

export default Loader;
