import React from "react";

function Footer() {
  return (
    <>
      {/********************************** Footer Starts Here *********************************************/}
      {/* <div className="footer-ablove">
        <div className="container">
          <div className="row">
            <p>
              Are you looking for a consultant for your Business
              <button className="btn btn-default">Get Quote</button>
            </p>
          </div>
        </div>
      </div> */}
      {/* <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-3 about">
              <h2>About Us</h2>
              <p>
                Phasellus scelerisque ornare nisl sit amet pulvinar. Nunc non
                scelerisque augue. Proin et sollicitudin velit.{" "}
              </p>
              <div className="foot-address">
                <div className="icon">
                  <i className="fas fa-map-marker-alt" />
                </div>
                <div className="addet">
                  BlueDart Marthandam (K.K District) Tamil Nadu, IND
                </div>
              </div>
              <div className="foot-address">
                <div className="icon">
                  <i className="far fa-envelope-open" />
                </div>
                <div className="addet">
                  info@smarteyeapps.com <br />
                  sales@smarteyeapps.com
                </div>
              </div>
              <div className="foot-address">
                <div className="icon">
                  <i className="fas fa-mobile-alt" />
                </div>
                <div className="addet">
                  +23 323 43434 <br />
                  +1 3232 434 55
                </div>
              </div>
            </div>
            <div className="col-md-3 fotblog">
              <h2>From latest Blog</h2>
              <div className="blohjb">
                <p>
                  dignissim. Integer tempor facilisis malesuada. Proin ac varius
                  velit, tincidunt condimentum
                </p>
                <span>22-1-2019</span>
              </div>
              <div className="blohjb">
                <p>
                  dignissim. Integer tempor facilisis malesuada. Proin ac varius
                  velit, tincidunt condimentum
                </p>
                <span>22-1-2019</span>
              </div>
            </div>
            <div className="col-md-3 glink">
              <ul>
                <li>
                  <a href="index.html">
                    <i className="fas fa-angle-double-right" />
                    Home
                  </a>
                </li>
                <li>
                  <a href="about_us.html">
                    <i className="fas fa-angle-double-right" />
                    About Us
                  </a>
                </li>
                <li>
                  <a href="services.html">
                    <i className="fas fa-angle-double-right" />
                    Services
                  </a>
                </li>
                <li>
                  <a href="blog.html">
                    <i className="fas fa-angle-double-right" />
                    Blog
                  </a>
                </li>
                <li>
                  <a href="pricing.html">
                    <i className="fas fa-angle-double-right" />
                    Gallery
                  </a>
                </li>
                <li>
                  <a href="contact_us.html">
                    <i className="fas fa-angle-double-right" />
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 tags">
              <h2>Easy Tags</h2>
              <ul>
                <li>Finance</li>
                <li>Web Design</li>
                <li>Internet Pro</li>
                <li>Node Js</li>
                <li>Java Swing</li>
                <li>Angular Js</li>
                <li>Vue Js</li>
              </ul>
            </div>
          </div>
        </div>
      </footer> */}
      <div className="copy">
        <div className="container">
          <a href="index.html">
            2022 © All Rights Reserved | {process.env.REACT_APP_PROJECT_NAME}
          </a>
          <span>
          <a href="#">
              <i className="fab fa-discord" />
            </a>
            <a href="#">
              <i className="fab fa-twitter" />
            </a>
            <a href="#">
              <i className="fab fa-telegram" />
            </a>
            {/* <a href="">
              <i className="fab fa-linkedin" />
            </a> */}
            {/* <a href="">
              <i className="fab fa-whatsapp" />
            </a>
            <a href=""> 
              <i className="fab fa-facebook-f" />
            </a> */}
          </span>
        </div>
      </div>
    </>
  );
}

export default Footer;
