import { VpnKey } from "@material-ui/icons";

import HomeLayout from "views/home";
import Artists from "views/artists";
import ArtistProfile from "views/artist-profile";
import NftDetails from "views/nft-details";
import UserNftList from "views/user-nfts";
// import ArtWorkCreateForm from "views/artworkCreate";
import ContactUs from "views/contact-us";
import BuyNftPage from "views/nft-details/buy-nft";

var adminRoutes = [];

var appRoutes = [
    {
        path: "/listings",
        name: "Login",
        icon: VpnKey,
        iconColor: "Info",
        component: HomeLayout,
        layout: "/auth",
    },
    {
        path: "/creators",
        name: "Artists List",
        icon: VpnKey,
        iconColor: "Info",
        component: Artists,
        layout: "/auth",
    },
    {
        path: "/creator/:creatorAddress",
        name: "Artist Profile",
        icon: VpnKey,
        iconColor: "Info",
        component: ArtistProfile,
        layout: "/auth",
    },
    {
        path: "/artwork/:nftAddress",
        name: "NFT Details",
        icon: VpnKey,
        iconColor: "Info",
        component: NftDetails,
        layout: "/auth",
    },
    {
        path: "/my-collections",
        name: "NFT Details",
        icon: VpnKey,
        iconColor: "Info",
        component: UserNftList,
        layout: "/auth",
    },
    {
        path: "/contactus",
        name: "Contact Us",
        icon: VpnKey,
        iconColor: "Info",
        component: ContactUs,
        layout: "/auth",
    },
    {
        path: "/buy-artwork/:nftAddress",
        name: "Buy NFT",
        icon: VpnKey,
        iconColor: "Info",
        component: BuyNftPage,
        layout: "/auth",
    },
];

export { adminRoutes, appRoutes };
