import React from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { FilledInput, Box, CircularProgress, Backdrop } from "@material-ui/core";

import CustomTextButton from "components/CustomButton/CustomTextButton";
import { convertToLamports } from "helpers";
import { moveNftToAnotherWallet } from "store/actions";

const MoveToWalletModalContent = ({ nftId, dispatch }) => {
    const { loading } = useSelector(({ loading }) => ({
        loading: loading,
    }));

    const handleMoveNow = ({ destWalletId }) => {
        dispatch(
            moveNftToAnotherWallet({
                nftId: nftId,
                destWalletId: destWalletId,
            })
        );
    };

    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <Formik
                initialValues={{ destWalletId: "" }}
                validationSchema={Yup.object().shape({
                    destWalletId: Yup.string().required("Wallet Address is required"),
                })}
                onSubmit={(values) => {
                    handleMoveNow({ destWalletId: values.destWalletId });
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="name" style={{ textTransform: "uppercase", color: "white", fontSize: 15 }}>
                            Wallet Address
                        </label>
                        <br />
                        <FilledInput
                            key="destWalletId"
                            name="destWalletId"
                            autoComplete="off"
                            type="text"
                            onBlur={handleBlur}
                            placeholder="Wallet Address"
                            onChange={handleChange}
                            value={values.destWalletId}
                            error={Boolean(touched.destWalletId && errors.destWalletId)}
                        />
                        {Boolean(touched.destWalletId && errors.destWalletId) ? (
                            <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 13 }}>
                                {errors.destWalletId}
                            </div>
                        ) : (
                            ""
                        )}
                        <Box my={2}>
                            <CustomTextButton
                                disabled={values.destWalletId === ""}
                                onClick={handleSubmit}
                                size="large"
                                type="submit"
                                variant="contained"
                                title="Confirm"
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default MoveToWalletModalContent;
